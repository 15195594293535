import logo from './logo.svg';
import './App.css';
import { useState } from 'react';

function App() {
  const [form, setForm] = useState({
    Name : ""
  });
  const handleChange = (e)=>{
    const {name, value} = e.target;
    setForm({...form, [name]: value});
  }
  const submit = async ()=>{
    await localStorage.setItem("name", form.Name)
    window.location = "/Hi"
  }
  return (
    <div className="App">
      <input value={form.Name} onChange={handleChange} name="Name" placeholder='Enter your name'/>
      <input type="submit" onClick={submit}/>
    </div>
  );
}

export default App;
